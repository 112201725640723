<template>
  <modal-form
    :title="$t('views.systemConfigurations.edit')"
    :fields="fields"
    :rules="rules"
    :key="configurationToUpdate._jv?.id"
    :loading="submitLoading"
    :visible="modalVisible"
    :cancelButton="cancelButton"
    @close-modal="closeModal"
    @submit-form="submitForm"
  />
  <div>
    <app-header :routes="routes" />
    <Main>
      <a-row :gutter="15">
        <a-col :xxl="24" :xl="24" :md="24" :xs="24">
          <sd-cards headless>
            <table-style-wrapper>
              <table-wrapper class="table-responsive">
                <a-table
                  :columns="columns"
                  :data-source="dataSource ? dataSource : []"
                  :rowKey="(record) => record._jv.id"
                  :pagination="pagination"
                  :loading="loading"
                  @change="handleTableChange"
                >
                  >
                  <template #key="{record}">
                    {{ $t(`models.systemConfiguration.attributes.keys.${record.key}`) }}
                  </template>

                  <template #value="{record}">
                    <template v-if="typeof record.value === 'boolean'">
                      <div class="table-icons">
                        <sdFeatherIcons :type="record.value ? 'check-circle' : 'x-circle'" size="18" />
                      </div>
                    </template>
                    <template v-else-if="record.key === 'bank_account_information'">
                      <a-tooltip>
                        <template #title>
                          {{ `${$t('models.systemConfiguration.attributes.bankInfo.bic')}: ${record.value.bic}` }}<br />
                          {{ `${$t('models.systemConfiguration.attributes.bankInfo.iban')}: ${record.value.iban}`
                          }}<br />
                          {{
                            `${$t('models.systemConfiguration.attributes.bankInfo.bankName')}: ${
                              record.value.bank_name
                            }`
                          }}<br />
                          {{
                            `${$t('models.systemConfiguration.attributes.bankInfo.beneficiaryName')}: ${
                              record.value.beneficiary_name
                            }`
                          }}<br />
                          {{
                            `${$t('models.systemConfiguration.attributes.bankInfo.currency')}: ${
                              record.value.currency
                            }`
                          }}<br />
                        </template>
                        <div class="table-icons">
                          <sdFeatherIcons type="help-circle" size="18" />
                        </div>
                      </a-tooltip>
                    </template>
                    <template v-else>
                      {{ record.value }}
                    </template>
                  </template>

                  <template #actions="{record}">
                    <div class="table-actions" v-if="$can('manage', 'systemConfiguration')">
                      <sd-button
                        class="btn-icon"
                        type="default"
                        shape="circle"
                        :tooltip="$t('actions.edit')"
                        @click="edit(record)"
                      >
                        <sd-feather-icons type="edit" :size="16" />
                      </sd-button>
                    </div>
                  </template>
                </a-table>
              </table-wrapper>
            </table-style-wrapper>
          </sd-cards>
        </a-col>
      </a-row>
    </Main>
  </div>
</template>

<script>
import {
  defineComponent, computed, reactive, ref
} from 'vue';
import {useStore} from 'vuex';
import {Main, TableWrapper} from '@/components/shared/styledComponents/Main';
import {TableStyleWrapper} from '@/components/tables/style';
import {useI18n} from 'vue-i18n';
import {usePagination} from 'vue-request';
import ModalForm from '@/components/modals/ModalForm';
import {
  required, requiredNumber, integer, rangeText,
  minRange, maxRange
} from '@/helpers/validationRules';
import {systemConfigurations} from '@/constants';
import _ from 'lodash';

export default defineComponent({
  name: 'ClientShipmentRequests',
  components: {
    Main,
    TableWrapper,
    TableStyleWrapper,
    ModalForm
  },
  setup() {
    const {dispatch, getters} = useStore();
    const {t} = useI18n();
    const routes = [
      {
        name: 'BackOfficeSystemConfigurations',
        breadcrumbName: 'views.systemConfigurations.title'
      }
    ];

    const columns = computed(() => [
      {
        title: t('models.systemConfiguration.attributes.key'),
        dataIndex: 'key',
        slots: {customRender: 'key'}
      },
      {
        title: t('models.systemConfiguration.attributes.value'),
        dataIndex: 'value',
        slots: {customRender: 'value'}
      },
      {
        title: t('models.systemConfiguration.attributes.unit'),
        dataIndex: 'unit'
      },
      {
        title: '',
        slots: {customRender: 'actions'}
      }
    ]);

    const getSystemConfigs = () => dispatch('getSystemConfigurations');
    getSystemConfigs();
    const {
      data: dataSource, run, loading, current, pageSize, mutate
    } = usePagination(getSystemConfigs, {
      formatResult: () => getters.systemConfigurations,
      pagination: {
        currentKey: 'page',
        pageSizeKey: 'perPage',
        totalKey: 'totalEntries'
      }
    });

    const handleTableChange = (pag, filters, sorter) => {
      run({
        per_page: pag.pageSize,
        page: pag?.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters
      });
    };

    const pagination = computed(() => ({
      total: getters.shipments?.totalEntries,
      current: current.value,
      pageSize: pageSize.value
    }));

    const modalVisible = ref(false);
    const submitLoading = ref(false);
    const cancelButton = {type: 'secondary', visible: true};
    const fieldType = (type) => {
      return (type === 'number' || type === 'integer' ? 'number' : undefined) || 'text';
    };

    const configurationToUpdate = ref({});
    const fields = computed(() => {
      return {
        key: {
          type: 'text',
          label: 'models.systemConfiguration.attributes.key',
          fieldValue: configurationToUpdate.value?.key ?
            t(`models.systemConfiguration.attributes.keys.${configurationToUpdate.value.key}`) :
            '',
          disabled: true,
          size: 'large'
        },
        value: {
          type: fieldType(systemConfigurations[_.toUpper(configurationToUpdate.value?.key)]?.type),
          label: 'models.systemConfiguration.attributes.value',
          fieldValue: !_.isEmpty(configurationToUpdate.value) ? configurationToUpdate.value.value : '',
          disabled: configurationToUpdate.value?.key === _.snakeCase('priceListDefaultCurrency'),
          size: 'large',
          hidden: configurationToUpdate.value.key === 'bank_account_information'
        },
        unit: {
          type: 'text',
          label: 'models.systemConfiguration.attributes.unit',
          fieldValue: configurationToUpdate.value?.unit || '',
          disabled: true,
          size: 'large',
          hidden: configurationToUpdate.value && !configurationToUpdate.value.unit
        },
        bic: {
          type: 'text',
          label: 'models.systemConfiguration.attributes.bankInfo.bic',
          fieldValue:
            configurationToUpdate.value?.key === 'bank_account_information' ?
              configurationToUpdate.value?.value.bic :
              '',
          hidden: configurationToUpdate.value && configurationToUpdate.value.key !== 'bank_account_information',
          size: 'large',
          class: 'mb-20'
        },
        iban: {
          type: 'text',
          label: 'models.systemConfiguration.attributes.bankInfo.iban',
          fieldValue:
            configurationToUpdate.value?.key === 'bank_account_information' ?
              configurationToUpdate.value?.value.iban :
              '',
          hidden: configurationToUpdate.value && configurationToUpdate.value.key !== 'bank_account_information',
          size: 'large',
          class: 'mb-20'
        },
        bankName: {
          type: 'text',
          label: 'models.systemConfiguration.attributes.bankInfo.bankName',
          fieldValue:
            configurationToUpdate.value?.key === 'bank_account_information' ?
              configurationToUpdate.value?.value.bank_name :
              '',
          hidden: configurationToUpdate.value && configurationToUpdate.value.key !== 'bank_account_information',
          size: 'large',
          class: 'mb-20'
        },
        beneficiaryName: {
          type: 'text',
          label: 'models.systemConfiguration.attributes.bankInfo.beneficiaryName',
          fieldValue:
            configurationToUpdate.value?.key === 'bank_account_information' ?
              configurationToUpdate.value?.value.beneficiary_name :
              '',
          hidden: configurationToUpdate.value && configurationToUpdate.value.key !== 'bank_account_information',
          size: 'large',
          class: 'mb-20'
        },
        currency: {
          type: 'text',
          label: 'models.systemConfiguration.attributes.bankInfo.currency',
          fieldValue:
            configurationToUpdate.value?.key === 'bank_account_information' ?
              configurationToUpdate.value?.value.currency :
              '',
          hidden: configurationToUpdate.value && configurationToUpdate.value.key !== 'bank_account_information',
          size: 'large',
          class: 'mb-20'
        }
      };
    });

    const rules = reactive({
      value: [required],
      bic: undefined,
      iban: undefined,
      bankName: undefined,
      beneficiaryName: undefined,
      currency: undefined
    });

    const edit = (config) => {
      configurationToUpdate.value = config;
      if (config.key === 'bank_account_information') {
        _.forEach(['bic', 'iban', 'bankName', 'beneficiaryName', 'currency'], (key) => (rules[key] = [required, rangeText(40)]));
      } else {
        _.forEach(['bic', 'iban', 'bankName', 'beneficiaryName', 'currency'], (key) => (rules[key] = undefined));

        if (systemConfigurations[_.toUpper(config.key)]?.type === 'text') {
          rules.value = [required];
        } else if (systemConfigurations[_.toUpper(config.key)]?.type === 'number') {
          rules.value = [requiredNumber, minRange(0)];
        } else if (systemConfigurations[_.toUpper(config.key)]?.type === 'integer') {
          rules.value = [integer, minRange(0)];
        } else {
          rules.value = undefined;
        }

        if (config.unit === 'days' || config.unit === 'hours') rules.value.push(integer);
        if (config.unit === 'percentage') rules.value.push(maxRange(100));
      }

      modalVisible.value = true;
    };

    const closeModal = () => {
      modalVisible.value = false;
      configurationToUpdate.value = {};
    };

    const submitForm = (data) => {
      const form = {value: {}};
      if (configurationToUpdate.value.key === 'bank_account_information') {
        _.forEach(['iban', 'bank_name', 'bic', 'beneficiary_name', 'currency'], (key) => {
          form.value[key] = data[key];
        });
      } else {
        form.value = data.value;
      }
      dispatch('updateSystemConfiguration', {id: configurationToUpdate.value._jv.id, form}).then((status) => {
        if (status === 200) {
          mutate(getters.systemConfigurations);
          closeModal();
        }
      });
    };

    return {
      routes,
      columns,
      pagination,
      dataSource,
      loading,
      fields,
      rules,
      submitLoading,
      modalVisible,
      cancelButton,
      handleTableChange,
      edit,
      submitForm,
      configurationToUpdate,
      closeModal
    };
  }
});
</script>
<style lang="scss">
.ant-tooltip .ant-tooltip-inner {
    display: inline-block;
    overflow-wrap: break-word!important;
}
</style>
